import { Injectable } from '@angular/core';
import { CoreModule } from '@app/core/core.module';
import { Store } from '@ngxs/store';
import { Logout } from '../states/models/auth.state.model';
import { ClearUser } from '../states/models/user.state.model';
@Injectable({
    providedIn: CoreModule
})
export class AccountMenuService {

    constructor(private store: Store) { }


    public getMenuItems(opts: { isAdmin: boolean, withBorder: boolean }) {
        return [
            {
                header: 'Accountmenu',
                label: 'Upgrade uw account',
                items: [],
                routerLink: '/upgrade',
                icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-star',
                emphasize: true,
                emphasizeLess: true,
                noBorder: !opts.withBorder
            },
            {
                label: 'Admin',
                icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-settings',
                hide: opts.isAdmin === false,
                items: [
                    {
                        label: 'Gebruikers',
                        icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-user',
                        routerLink: '/admin/user-management',
                        items: []
                    },
                    {
                        label: 'Orders',
                        icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-shopping-cart',
                        routerLink: '/admin/order-item-management',
                        items: []
                    },
                    {
                        label: 'Blacklist',
                        icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-help-circle',
                        routerLink: '/admin/blacklist',
                        items: []
                    },
                    {
                        label: 'API-keys',
                        icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-external-link',
                        routerLink: '/admin/api-key-management',
                        items: []
                    }
                ]
            },
            {
                label: 'Dashboard',
                icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-settings',
                routerLink: '/profiel/dashboard',
                items: [],
                noBorder: !opts.withBorder

            },
            // {
            //     label: 'Mijn winkelwagen',
            //     subLabel: this.itemsInCart === 1 ? `${this.itemsInCart} product` : `${this.itemsInCart} producten`,
            //     items: [],
            //     routerLink: '/afrekenen/besteloverzicht',
            //     icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-shopping-cart'
            // },
            {
                label: 'Mijn percelen',
                icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-layout',
                routerLink: '/profiel/mijn-percelen',
                items: [],
                noBorder: !opts.withBorder

            },
            {
                label: 'Mijn alerts',
                icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-bell',
                routerLink: '/profiel/mijn-alerts',
                items: [],
                noBorder: !opts.withBorder

            },
            {
                label: 'Mijn bestellingen',
                icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-shopping-cart',
                routerLink: '/profiel/mijn-bestellingen',
                items: [],
                noBorder: !opts.withBorder

            },
            {
                label: 'Mijn facturen',
                icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-inbox',
                routerLink: '/profiel/mijn-facturen',
                items: [],
                noBorder: !opts.withBorder

            },
            {
                label: 'Mijn API-sleutels',
                icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-key',
                routerLink: '/profiel/mijn-api-keys',
                items: [],
                noBorder: !opts.withBorder

            },
            {
                label: 'Mijn lidmaatschap',
                icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-columns',
                routerLink: '/profiel/mijn-lidmaatschap',
                items: [],
                noBorder: !opts.withBorder

            },
            {
                label: 'Accountbeheer',
                icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-settings',
                routerLink: '/profiel/beheer',
                items: [],
                noBorder: !opts.withBorder

            },
            {
                label: 'Uitloggen',
                items: [],
                click: () => this.logout(),
                icon: 'assets/icons/svg/all_svg_icons-10.svg#icon-log-out',
                noBorder: !opts.withBorder
            },
        ];
    }


    public async logout() {
        await this.store.dispatch([new Logout(), new ClearUser()]).toPromise();
    }
}

import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'app-spin',
  template: `
  @if (showSpinner()) {
    <div class="lds-dual-ring"></div>
  }
 `,
  styles: [
    `
      .lds-dual-ring {
      display: inline-block;
    }

    .lds-dual-ring:after {
      content: " ";
      display: block;
      width: 17px;
      height: 17px;
      border-radius: 100%;
      border: 2px solid #fff;
      border-color: #fff transparent #fff transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }

    @keyframes lds-dual-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinComponent {
  public showSpinner = input<boolean>(true);
}

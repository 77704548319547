import { UserParcel } from "@app/core/interfaces/user-parcel.model";

export interface GetUserParcelParams {
    auth0Id: string,
    limit: string,
    offset: string,
    sortBy?: string,
    tags?: string[],
    searchTerm?: string,
    minSize?: string,
    maxSize?: string,
    bbox?: string
}

export class UserParcelsStateModel {
    userParcels: UserParcel[];
    tags: string[]
}

export class ListUserParcels {
    static readonly type = '[UserParcels] List';
    constructor(public payload: GetUserParcelParams) {
    }
}

export class CreateUserParcel {
    static readonly type = '[UserParcels] Create';
    constructor(public payload: { parcel: Partial<UserParcel>, auth0Id: string }) {
    }
}

export class BulkCreateUserParcel {
    static readonly type = '[UserParcels] Bulk Create';
    constructor(public payload: { parcels: Partial<UserParcel>[], auth0Id: string }) {
    }
}

export class RemoveUserParcel {
    static readonly type = '[UserParcels] Remove';
    constructor(public payload: { parcelId: string, userAuth0Id: string }) {
    }
}

export class UpdateUserParcel {
    static readonly type = '[UserParcels] Update';
    constructor(public payload: { parcelId: string, parcel: UserParcel }) {
    }
}


export class AppendUserParcels {
    static readonly type = '[UserParcels] List and append';
    constructor(public payload: GetUserParcelParams) {
    }
}

export class ListTags {
    static readonly type = '[UserParcels] ListTags';
    constructor(public payload: {
        userAuth0Id: string
    }) {
    }
}




import { Observable, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { CoreModule } from '../core.module';
import { Post, Stories } from '../interfaces/storyblok.interface';

@Injectable({
  providedIn: CoreModule
})
export class StoryBlokService {
  private baseUrl = 'https://api.storyblok.com/v2/cdn/stories';
  private token = environment.storyBlokAccessToken;

  constructor(private httpClient: HttpClient) { }

  public listPosts(startsWith: string, params?: {perPage?: number, withTag?: string[], publishedAtGt?: string, firstPublishedAtGt?: string}): Observable<any> {
    return this.httpClient.get(this.baseUrl, {
      params: {
        version: environment.storyBlokVersion,
        starts_with: startsWith,
        token: this.token,
        cv: `${new Date().getTime()}`,
        sort_by: "published_at:desc",
        per_page: params?.perPage || 100,
        ...(params?.publishedAtGt ? {published_at_gt: params.publishedAtGt} : {}),
        ...(params?.firstPublishedAtGt ? {first_published_at_gt: params.firstPublishedAtGt} : {}),
        ...(params?.withTag ? {with_tag: params.withTag.join(',')} : {}),
      }
    })
  }

  public getPost(slug: string) {
    return this.httpClient.get<Post>(`${this.baseUrl}${slug}`, {
      params: {
        token: this.token,
        cv: `${new Date().getTime()}`,
        version: environment.storyBlokVersion
      }
    });
  }

  public getRelatedPosts(id: number) {
    return this.httpClient.get<Stories>(this.baseUrl, {
      params: {
        version: environment.storyBlokVersion,
        token: this.token,
        cv: `${new Date().getTime()}`,
        excluding_ids: id,
        per_page: '6'
      }
    });
  }

  public getRelatedPostsByAlias(alias) {
    return this.httpClient.get(`${this.baseUrl}?with_tag=${alias}`, {
      params: {
        version: environment.storyBlokVersion,
        token: this.token,
        cv: `${new Date().getTime()}`,
        // excluding_ids: id,
        per_page: '1'
      }
    });
  }



}

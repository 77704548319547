import { Injectable, ErrorHandler, Inject, NgZone, ChangeDetectorRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
import { RollbarService } from '@app/core/plugins/rollbar/rollbar.service';
import { NotificationService } from '../services/notification.service';

@Injectable()
export class GeneralErrorHandler implements ErrorHandler {

  public triggered = false;
  constructor(
    // Because the GeneralErrorHandler is created before the providers, we’ll have to use the Injector to get them.
    @Inject(RollbarService) private rollbarService: RollbarService,
    private notificationService: NotificationService,
    private zone: NgZone,
  ) {

    // Only enable this if we use ZonelessChangeDetection
    if (environment.production) {
      window.addEventListener('error', this.handleErrorEvent.bind(this));
      window.addEventListener('unhandledrejection', this.handleUnhandledRejection.bind(this));
    } else {
      window.onerror = this.handleErrorEvent.bind(this);
      window.onunhandledrejection = this.handleUnhandledRejection.bind(this);
    }
  }


  private handleErrorEvent(event: ErrorEvent) {
    console.log(event);

    if (event.preventDefault) {
      event?.preventDefault();
    }

    if (event.error instanceof ErrorEvent === false) {
      return '';
    }

    return this.handleError(event.error);
  }

  private handleUnhandledRejection(event: PromiseRejectionEvent) {
    console.log(event);

    if (event.preventDefault) {
      event?.preventDefault();
    }
    this.handleError(event.reason);
  }

  public handleError(error: Error | HttpErrorResponse | any) {
    /* This is a fix for handling uncaught promise errors */
    this.handleChunkLoadFailedError(error)

    if (environment.rollbar.enableRollbar) {
      this.logError(error);

    } else {
      console.error(error);
    }

    return this.notifyError(error);
  }

  private handleChunkLoadFailedError(err: Error) {
    const chunkFailedMessage = /Importing a module/;

    if (chunkFailedMessage.test(err?.message)) {
      window.location.reload();
    }

    const anotherMessage = /Failed to fetch dynamically/;
    if (anotherMessage.test(err?.message)) {
      window.location.reload();
    }

    const moreMessages = /Loading chunk/;
    if (moreMessages.test(err?.message)) {
      window.location.reload();
    }
  }

  private notifyError(error: Error | HttpErrorResponse | any) {
    if (error?.message === 'InvalidAuthDetailsError') {  
      return this.openSnackbar('Onjuiste inloggegevens.', 'OK');
    }
    
    if (error?.message === 'EmailValidationError') {
      return this.openSnackbar('Dit emailadres is onjuist geformatteerd.');
    }

    if (error?.message === 'AlreadyInCartError') {
      return this.openSnackbar('Dit rapport bevindt zich al in uw winkelwagen.', 'OK');
    }

    if (error?.message === 'NoGpsFoundError') {
      return this.openSnackbar('Geen GPS beschikbaar.', 'OK');
    }

    if (error?.message === 'ParcelNotFoundError') {
      return this.openSnackbar('Geen perceel gevonden op deze plek.', 'OK');
    }

    if (error?.message === 'UnavailableProductError') {
      // TODO dit netter afhandelen (door de kaart te tonen oid)
      return this.openSnackbar('Dit product is niet beschikbaar.', 'OK');
    }

    if (error?.message === 'User denied Geolocation') {
      return this.openSnackbar('Geen toestemming voor geolocatie ontvangen.', 'OK');
    }

    if (error?.message === 'AreaTooLargeError') {
      return this.openSnackbar('Het opgevraagde gebied is te groot. Zoom verder in op de kaart en probeer het opnieuw.', 'OK');
    }

    if (error instanceof HttpErrorResponse) {
      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
        return this.openSnackbar('Geen internetverbinding beschikbaar.');
      }
      /* Nog niet heel mooi, maar komt er wel */
      if (error?.error?.error === 'ParcelSaveLimitReachedError') {
        return this.openSnackbar(error.error.message, 'Sluiten');
      }

      if (error?.error?.error === 'MaxFileUploadLimitReachedError') {
        return this.openSnackbar(error.error.message, 'Sluiten');
      }

      if (error?.error?.error === 'ApiError') {
        return this.openSnackbar(error.error.message, 'Sluiten');
      }


      // Handle Http Error (error.status === 403, 404...)
      switch (error.status) {
        case 401:
          return this.openSnackbar('U mag deze actie niet uitvoeren zonder lidmaatschap.');
        case 403:
          return this.openSnackbar('U mag deze actie niet uitvoeren met uw huidige lidmaatschap.');
        case 404:
          return this.openSnackbar(`De opgevraagde informatie kan niet worden gevonden.`);
        default:
          return this.openSnackbar('Er is een fout opgetreden tijdens het verbinding maken met de server.');
      }
    } else {
      // // Handle Client Error (Angular Error, ReferenceError...)
      if (this.triggered === false) {
        const snackbar = this.notificationService.getSnackbar().then((snackbar) => {
          this.zone.run(() => {
            snackbar
              .open('Er is een fout opgetreden in de applicatie. Ververs de pagina.', 'OK', {
                duration: 2500,
                panelClass: 'styled-snackbar',
              })
              .onAction()
              .subscribe(() => {
                window.location.reload();
              });
          });
        });
        this.triggered = true;
      }

    }
  }

  private openSnackbar(message: string, action = 'OK') {
    const snackbar = this.notificationService.getSnackbar().then((snackbar) => {
      snackbar.open(message, action, { duration: 4000, panelClass: ['styled-snackbar'] });
    });

    return;
  }

  private logError(error: any) {
    console.log(error?.originalError || error);
    this.rollbarService.getRollbar().then(rollbar => {
      this.rollbarService.finalUrls.forEach((url, indx) => console.log(indx, ':', url))
      rollbar.error(...this.rollbarService.sanitizeError(error?.originalError || error))
    })
    // Prevent double error logging to rollbar. Rollbar has an event listener at the console. Throwing another error here will cause it to appear multiple times.
    console.warn(error);
  }
}
